<template>
	<div>
		<div class="pt-170">
			<div class="bg-verbena sun-404">
				<div class="mode-center">
					<div class=" p-32 font-national text-1.5 text-crab">
						{{text_404}}
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { globals } from "@/modules/globals"

export default {
  name: "page_404",
  props: [],
  data() {
    return {
		text_404: null
    }
  },
  mounted() {
	this.text_404 = globals.text.text_404[globals.language]
  }
};
</script>

<style>
	.sun-404 {
		background: url(/img/sun-big-down.9f03a355.svg);
		background-repeat: no-repeat;
		background-position: center bottom;
		background-size: 100vw;
	}
</style>
