<template>
	<div class=" duration-300 ease-in-out">
		<div
			class="mode-center pt-170 px-20 items-baseline mx900:grid-cols-1 mn640:pt-280 mx640:pb-100 mx640:px-10 text-pine"
		>
			<div class="mb-48">
				<div
					v-if="body"
					v-html="body"
					class="rich-text anime-hover text-24 font-inter"
				></div>
			</div>
		</div>
	</div>
</template>

<script>
	import { globals } from "@/modules/globals";

	var thisVue = null;

	export default {
		name: "Privacy",
		components: {},

		data() {
			return {
				body: "test",
			};
		},
		beforeRouteEnter(to, from, next) {
			let dataFiles = 1;

			let sectionsData = null;
			let json_file_sections =
				"/json/sections/sections_privacy-statement_" +
				globals.language +
				".json?v=" +
				globals.content_version;
			fetch(json_file_sections)
				.then((response) => response.json())
				.then(function(data) {
					sectionsData = data;
					afterJsonLoaded();
				})
				.catch((error) => {
					console.error("FETCH Error:", error);
				});

			let countLoaded = 0;
			function afterJsonLoaded() {
				countLoaded++;
				if (countLoaded == dataFiles) {
					next((vm) => vm.setData(sectionsData));
				}
			}
		},
		mounted() {
			thisVue = this;
		},
		methods: {
			setData(sectionsData) {
				this.body = sectionsData.privacy["body"];
			},
		},
	};
</script>

<style></style>
